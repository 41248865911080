import { FC } from 'react';

import * as s from 'web/lib/components/Bead.module.scss';

interface BeadProps {
    color?: string;
    style?: React.CSSProperties;
}

const Bead: FC<BeadProps> = ({ color = 'gray', ...rest }) => {
    return <div className={`${s.bead} ${s[color]}`} {...rest} />;
};

export default Bead;
