const webEnv = {
    apiBaseUrl: process.env.API_BASE_URL || 'http://localhost:1786'!,
    pubApiBaseUrl: process.env.PUB_API_BASE_URL || 'http://localhost:1793'!,
    scmsApiBaseUrl: process.env.SCMS_API_BASE_URL || 'http://localhost:1796'!,
    fileApiBaseUrl: process.env.FILE_API_BASE_URL || 'http://localhost:1798'!,
    hrmsApiBaseUrl: process.env.HRMS_API_BASE_URL || 'http://localhost:1797'!,
    webBaseUrl: process.env.WEB_BASE_URL!,
    gcpProjectNumber: process.env.GCP_PROJECT_NUMBER!,
    stripeKey: process.env.STRIPE_PUBLIC_KEY!,
    stripeCusDashboardUrl: process.env.STRIPE_CUS_DASHBOARD_URL!,
    googleDriveClientId: process.env.GOOGLE_DRIVE_CLIENT_ID!,
    googleDriveApiKey: process.env.GOOGLE_DRIVE_API_KEY!,
    dropboxAppKey: process.env.DROPBOX_APP_KEY!,
    oneDriveClientId: process.env.ONEDRIVE_CLIENT_ID!,
    recaptchaKey: process.env.RECAPTCHA_KEY!,
    whatsappAppID: process.env.WHATSAPP_APP_ID!,
    whatsappApiVersion: process.env.WHATSAPP_API_VERSION!,
    whatsappConfigID: process.env.WHATSAPP_CONFIG_ID!,
    autoRefreshNotifList: process.env.AUTO_REFRESH_NOTIF_LIST!,
};

declare global {
    var env: typeof webEnv;
}

globalThis.env = webEnv;

export default webEnv;
