import { FC, useState, useEffect } from 'react';

import Button from 'web/lib/components/Button.tsx';
import Heading from 'web/lib/components/Heading.tsx';
import Text from 'web/lib/components/Text.tsx';
import Bead from 'web/lib/components/Bead.tsx';

import * as s from 'web/lib/components/ErrorBoundary.module.scss';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const handleError: any = (error: Error, errorInfo: React.ErrorInfo) => {
            console.error('err:', error, errorInfo);
            setHasError(true);
        };
        window.addEventListener('error', handleError);

        return () => {
            window.removeEventListener('error', handleError);
        };
    }, []);

    if (hasError) {
        return (
            <>
                <div className={s.pageWrapper}>
                    <div>
                        <div className={s.errorBoundaryImg}>
                            <div className={s.errorExclamation}>
                                <div className={s.errorLine} />
                                <Bead
                                    color="darkGray"
                                    style={{
                                        margin: '8px auto 0',
                                        height: '10px',
                                        width: '10px',
                                    }}
                                />
                            </div>
                        </div>
                        <Heading
                            size={2}
                            style={{
                                margin: '30px auto 0',
                            }}
                        >
                            Sorry, something went wrong!
                        </Heading>
                        <Text
                            style={{
                                textAlign: 'center',
                                width: '500px',
                                margin: '30px auto 0',
                            }}
                        >
                            We are working on it and we will get it fixed as soon as we can. Please try reloading the
                            page.
                        </Text>
                        <Button
                            onClick={() => {
                                window.location.reload();
                            }}
                            style={{
                                margin: '38px auto 0',
                            }}
                        >
                            Reload
                        </Button>
                    </div>
                </div>
            </>
        );
    }
    return <>{children}</>;
};

export default ErrorBoundary;
